import { paperShadow } from '@/providers/theme/themes';
import { Box, FormHelperText, useTheme } from '@mui/material';
import { useField } from 'formik';
import { ReactNode } from 'react';
import type { PhoneInputProps } from 'react-phone-input-2';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import TextFieldInputLabel from '../inputLabel';

export default function FormPhoneField( {
	name,
	label,
	tooltip,
	size = 'small',
	...props
}: {
	name: string,
	label?: string,
	tooltip?: ReactNode,
	size?: 'small' | 'medium'
} & PhoneInputProps ) {
	const [ , { value = null, touched, error }, { setValue, setTouched } ] = useField( name );
	const theme = useTheme();
	
	return (
		<Box
			sx={{
				'width'                                                                       : '100%',
				'.react-tel-input .form-control'                                              : {
					position    : 'relative',
					bgcolor     : theme.palette.mode === 'dark' && theme.palette.background.default + ' !important',
					boxShadow   : paperShadow,
					color       : 'text.primary',
					borderColor : 'divider',
					width       : '100%',
					height      : size === 'small' ? 38 : 48,
					borderRadius: 2,
				},
				'.react-tel-input .form-control:focus'                                        : {
					borderColor: 'primary.main',
				},
				'.react-tel-input .form-control:hover'                                        : {
					borderColor: 'colors.info.hover',
					transition : '.3s',
				},
				'.react-tel-input .flag-dropdown'                                             : {
					bgcolor         : 'transparent !important',
					border          : 'none',
					borderRight     : 1,
					borderRightColor: 'divider',
				},
				'.react-tel-input .flag-dropdown:hover'                                       : {
					bgcolor: 'transparent !important',
				},
				'.react-tel-input .flag-dropdown.open .selected-flag'                         : {
					bgcolor: 'transparent !important',
				},
				'.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus': {
					bgcolor: 'transparent !important',
				},
				'.react-tel-input .country-list'                                              : {
					bgcolor     : 'background.paper',
					overflow    : 'overlay',
					boxSizing   : 'border-box',
					borderRadius: 1,
					border      : 1,
					borderColor : 'divider',
					mt          : .5,
					px          : 1,
					maxHeight   : '200px',
					width       : '274px',
					position    : { sm: 'fixed' },
				},
				'.react-tel-input .country-list .country'                                     : { p: 1, my: .5 },
				'.react-tel-input .country-list .country.highlight'                           : {
					bgcolor     : 'primary.main',
					borderRadius: 1,
				},
				'.react-tel-input .country-list .country:not([aria-selected="true"]):hover'   : {
					bgcolor     : 'divider',
					borderRadius: 1,
				},
			}}>
			<TextFieldInputLabel label={label} tooltip={tooltip}/>
			<PhoneInput
				country='us'
				value={value}
				onChange={( value ) => setValue( value )}
				onBlur={() => setTouched( true )}
				{...props}
			/>
			{touched && Boolean( error ) && <FormHelperText error>{error}</FormHelperText>}
		</Box>
	);
}
