import { Box, BoxProps, ListItem, ListItemProps, ListItemText, ListItemTextProps } from '@mui/material';
import type { ReactNode } from 'react';
import Actions, { ActionPropsArray } from '../actions';

export type PageSectionProps = {
	actions?: ActionPropsArray | ReactNode,
	boxProps?: BoxProps,
	listItemProps?: ListItemProps,
	children?: ReactNode,
	divider?: boolean,
	hide?: boolean,
	sideTitle?: string
} & ListItemTextProps;

export default function PageSection( {
	actions,
	listItemProps,
	hide,
	boxProps,
	children,
	divider = true,
	...props
}: PageSectionProps ) {
	return (
		<Box display='flex' flexDirection='column' overflow='hidden' {...boxProps}>
			{!hide && (
				<ListItem
					disableGutters
					component='div'
					sx={{ mb: 1, pb: .5 }}
					divider={divider}
					{...listItemProps as any}>
					<ListItemText
						{...props}
						sx={{ mb: 0, ...props?.sx }}
						primaryTypographyProps={{ variant: 'h4', ...props.primaryTypographyProps }}
					/>
					<Box p={.2} sx={{ display: 'flex', alignSelf: 'flex-end' }}>
						{Array.isArray( actions )
							? <Actions separated items={actions} max={5}/>
							: actions}
					</Box>
				</ListItem>
			)}
			{children}
		</Box>
	);
}
