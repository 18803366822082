import type { TextFieldProps } from '@mui/material';
import { Box, TextField } from '@mui/material';
import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import TextFieldInputLabel from './form/inputLabel';

export type FormattedTextFieldProps = {
	tooltip?: ReactNode,
	isError?: boolean,
	disableBrowserAutoComplete?: boolean,
	labelEndNode?: ReactNode
} & TextFieldProps;

export default function FormattedTextField( {
	tooltip,
	value,
	label,
	isError,
	labelEndNode,
	disableBrowserAutoComplete = false,
	...props
}: FormattedTextFieldProps ) {
	const [ text, setText ] = useState( value );
	const [ focused, setFocused ] = useState( false );
	
	const inputRef = useRef( null );
	
	useEffect( () => {
		if ( !focused ) setText( value );
	}, [ focused, value ] );
	
	return (
		<Box width='100%'>
			<TextFieldInputLabel
				isError={isError}
				label={label}
				tooltip={tooltip}
				labelEndNode={labelEndNode}
			/>
			<TextField
				inputRef={inputRef}
				autoComplete={disableBrowserAutoComplete ? 'new-password' : undefined}
				{...props}
				value={text}
				focused={focused}
				onFocus={( e ) => {
					setFocused( true );
					props.onFocus?.( e );
				}}
				onChange={( e ) => {
					setText( e.target.value );
					props.onChange?.( e );
				}}
				onBlur={( e ) => {
					setText( value );
					setFocused( false );
					props.onBlur?.( e );
				}}
			/>
		</Box>
	);
}
